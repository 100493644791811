<template>
  <div v-if="session.slot" class="detailSesi">
    <a-modal
      title="Update Assignment"
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      id="modal-update-confirmation"
    >
      <p>Are you sure to update your assignment?</p>
    </a-modal>
    <div id="coverDetail">
      <!-- Sebelum lempar props : {{currentSlot.id}} -->
      <h2>
        {{ kelas }} |
        {{ mapel }}
      </h2>
      <div>
        Session {{ week }} / 24 |
        {{
          session.topik && session.topik.nama ? topik(session.topik.nama) : "-"
        }}
        | {{ sessionDate }}, {{ sessionTime }}
      </div>
    </div>
    <div
      v-if="isInSubjectHours && liveClass.zoom_setting"
      class="countdownTImer"
    >
      <h4>SESSION TIME LEFT</h4>
      <h2>{{ formattedDuration }}</h2>
    </div>
    <div id="contentDetail">
      <div class="d-flex flex-column flex-lg-row align-items-center">
        <div class="w-100 w-lg-75">
          <a-menu class="menuSchedule" v-model="current" mode="horizontal">
            <a-menu-item key="live class" class="subMenuDetail">
              <a-icon
                type="video-camera"
                style="color: #ff0000; font-size: 22px"
                v-if="isLive && isNow"
              />
              {{ isLive && isNow ? "LIVE CLASS" : "NOTES & DETAILS" }}
            </a-menu-item>
            <a-menu-item key="materials" class="subMenuDetail"
              >MATERIALS</a-menu-item
            >
            <a-menu-item id="assignments-student" key="assignments" class="subMenuDetail"
              >ASSIGNMENTS</a-menu-item
            >
          </a-menu>
        </div>
        <div
          v-if="
            current[0] === 'assignments' &&
            session.tipe_assignment &&
            session.tipe_assignment !== 'File Upload' &&
            !assignmentStudent.tugas_nilai
          "
          class="w-auto d-flex flex-column flex-md-row mt-2 ml-3"
        >
          <a-button
            id="do-assignment"
            shape="round"
            :type="doAssignment ? 'danger' : 'primary'"
            :icon="doAssignment ? 'close' : 'edit'"
            :disabled="attendanceIsNull ? true : false"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            @click="doAssignmentClick"
            >{{ doAssignment ? "CANCEL" : "DO ASSIGNMENT" }}</a-button
          >
          <a-button
            id="submit-assignment"
            v-if="doAssignment"
            @click.prevent="saveDoAssignment"
            style="background-color: #41b883; color: white"
            shape="round"
            icon="save"
            >SUBMIT</a-button
          >
        </div>
      </div>
      <div>
        <!-- <transition-group name="slide-fadein-up"> -->
        <LiveClass
          v-if="liveClass"
          v-show="current[0] === 'live class'"
          :liveClass="liveClass"
          :assignmentStudent="assignmentStudent"
          :isStudent="true"
          :isNow="isNow"
          @addUploadedImages="addUploadedImages"
          :session="session"
          :model="model"
        />
        <Materials
          v-show="materials && current[0] === 'materials'"
          :videoUrls="videoUrls"
          :materials="materials"
          :fileMaterials="fileMaterials"
        />
        <Assignments
          key="3"
          v-show="current[0] === 'assignments'"
          :session="session"
          :taskType="session.tipe_assignment"
          :doAssignment="doAssignment"
          :assignmentStudent="assignmentStudent"
          :attendanceIsNull="attendanceIsNull"
          @changeAttendanceIsNull="changeAttendanceIsNull"
          @changedEssayAnswer="changedEssayAnswer"
          @changedMultipleChoiceAnswer="changedMultipleChoiceAnswer"
        />
      </div>
      <!-- </transition-group> -->
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import LiveClass from '@/components/app/DetailSchedule/LiveClass'
import Materials from '@/components/app/DetailSchedule/Materials'
import Assignments from '@/components/app/DetailSchedule/Assignments'
// import $ from 'jquery'
// import { ZoomMtg } from '@zoomus/websdk'

export default {
  name: 'DetailScheduleStudent',
  data() {
    return {
      attendanceIsNull: false,
      doAssignment: false,
      idWeek: null,
      isEdit: false,
      src: '',
      meetConfig: {},
      signature: {},
      liveClass: {},
      assignmentStudent: {},
      essayAnswer: '',
      multipleChoiceAnswer: '',
      modalVisible: false,
      confirmLoading: false,
      time: moment(),
      nextTime: null,
      duration: 0,
      formattedDuration: '00:00:00',
      uploadedImages: [],
      idSession: this.$route.params.id,
      session: {},
      model: {
        current: {},
        next: {},
      },
      idNextSession: null,
    }
  },
  components: {
    LiveClass,
    Materials,
    Assignments,
  },
  methods: {
    addUploadedImages(payload) {
      this.uploadedImages = payload
    },
    makeDuration() {
      const currentTime = moment().locale('id')
      const diffTime = this.nextTime - currentTime
      console.log(this.nextTime, this.session, this.model)
      this.duration = moment.duration(diffTime, 'milliseconds')
      // console.log('masuk sini dessh')
      const timerInterval = setInterval(async () => {
        if (this.duration <= 0) {
          if (this.idNextSession && this.isInSubjectHours) {
            const data = await this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
              idMurid: this.user.id,
              idSession: this.idNextSession,
            })
            this.fetchSession(this.idNextSession)
            if (data.data) {
              this.assignmentStudent = data.data
              this.essayAnswer = this.assignmentStudent?.essay_answer || ''
              this.multipleChoiceAnswer = this.assignmentStudent?.jawaban_murid || ''
            }
          }
          clearInterval(timerInterval)
        } else {
          // console.log('masuk sini deh')
          this.duration = moment.duration(this.duration - 1000, 'milliseconds')
          this.formattedDuration = `${this.duration.hours() >= 10 ? '' : '0'}${this.duration.hours()}:${this.duration.minutes() >= 10 ? '' : '0'}${this.duration.minutes()}:${this.duration.seconds() >= 10 ? '' : '0'}${this.duration.seconds()}`
        }
      }, 1000)
      // return this.nextTime.diff(this.time, 'miliseconds')
    },
    findWeekSlot(idSlot) {
      const { slots } = this.$store.state.slot
      slots.map(slot => {
        if (slot.id === this.$route.params.id) {
          this.idWeek = slot.id
        }
      })
    },
    topik(text) {
      let string = ''
      if (text.length > 25) {
        string = text.substring(0, 50) + '...'
      } else {
        string = text
      }
      return string
    },
    saveDoAssignment() {
      this.modalVisible = true
    },
    async handleOk(e) {
      try {
        let apiIsSuccess = false
        this.confirmLoading = true
        const tipeAssignment = this.session.tipe_assignment
        let answer
        if (tipeAssignment === 'Essay') {
          answer = this.essayAnswer
        } else if (tipeAssignment === 'Multiple Choice') {
          answer = this.multipleChoiceAnswer
        }
        apiIsSuccess = await this.$store.dispatch('sesi/UPDATE_ANSWER', {
          tipeAssignment: tipeAssignment,
          answer: answer,
          idMurid: this.user.id,
          idSesi: this.session.id,
        })
        const data = await this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
          idMurid: this.user.id,
          idSession: this.session.id,
        })
        if (data.data) {
          this.assignmentStudent = data.data
          this.essayAnswer = this.assignmentStudent?.essay_answer || ''
          this.multipleChoiceAnswer = this.assignmentStudent?.jawaban_murid || ''
        }
        setTimeout(() => {
          this.doAssignment = false
          this.modalVisible = false
          this.confirmLoading = false
          if (apiIsSuccess) {
            this.$notification.success({
              message: 'Successfully update notes',
            })
          } else {
            this.$notification.error({
              message: 'Sorry.',
              description: 'Notes can\'t be updated right now. Try again later.',
            })
          }
        }, 1000)
      } catch (err) {
        this.doAssignment = false
        this.modalVisible = false
        this.confirmLoading = false
        this.$notification.error({
          message: 'Sorry.',
          description: 'Notes can\'t be updated right now. Try again later.',
        })
        return new Promise((resolve, reject) => reject(err))
      }
      // e.preventDefault()
    },
    handleCancel(e) {
      // e.preventDefault()
      this.modalVisible = false
    },
    doAssignmentClick() {
      if (this.doAssignment) {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to cancel this assignment ? Your changes will be lost </div>
          ),
          onOk: () => {
            this.doAssignment = false
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'danger',
          okText: 'Yes',
          cancelText: 'No',
        })
      } else {
        this.doAssignment = true
      }
    },
    changeAttendanceIsNull() { this.attendanceIsNull = true },
    changedEssayAnswer(answer) {
      // console.log(answer)
      this.essayAnswer = answer
    },
    changedMultipleChoiceAnswer(answer) {
      // console.log(answer)
      this.multipleChoiceAnswer = answer
      // console.log('Multiple Choice', this.multipleChoiceAnswer)
    },
    async fetchSession(idSession = this.idSession) {
      try {
        const { session, idNextSession, model } = await this.$store.dispatch('slot/FETCH_CURRENT_SLOT', { idSession })
        this.session = session
        this.idNextSession = idNextSession
        this.model = model
        const { zoom_setting: zoomSetting } = this.session
        this.time = moment(`${this.session.tanggal_sesi} ${this.model.current.jam_mulai}`, 'YYYY-MM-DD HH:mm')
        this.nextTime = this.model.next.jam_mulai ? moment(`${this.session.tanggal_sesi} ${this.model.next.jam_mulai}`, 'YYYY-MM-DD HH:mm', 'HH:mm') : null
        this.liveClass = {
          zoom_setting: this.session.zoom_setting,
          student: this.session.slot.kela.kelas_tahun_ajaran,
          kelas: this.session.slot.kela,
          video_conference: this.institusi.video_conference,
        }
        if (zoomSetting && this.isNow && this.nextTime) {
          this.makeDuration()
        } else {
          this.$store.commit('live/SET_STATE', {
            isLive: false,
          })
        }
        return new Promise((resolve, reject) => resolve(session))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  async created() {
    try {
      const { idSession } = this
      const data = await this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
        idMurid: this.user.id,
        idSession,
      })
      this.assignmentStudent = data.data
      this.essayAnswer = this.assignmentStudent?.essay_answer || ''
      this.multipleChoiceAnswer = this.assignmentStudent?.jawaban_murid || ''
      await this.fetchSession()

      if (this.session.topik?.id) {
        await this.$store.dispatch('slot/FETCH_FILE_MATERIAL', { idTopik: this.session.topik.id })
      }

      if (this.session.zoom_setting && this.liveClass.video_conference === 'zoom' && this.isNow) {
        const { kela: kelas } = this.session.slot
        if (kelas.zoom_url && kelas.zoom_password) {
          const protocol = this.isMobile ? 'zoomus://' : 'zoommtg://'
          let link = `${protocol}zoom.us/join?`
          const config = {
            confno: kelas.zoom_url,
            pwd: kelas.zoom_password,
            zc: 0,
          }

          for (const key in config) {
            link += `${key}=${config[key]}&`
          }
          const a = document.createElement('a')
          a.href = link
          a.click()
        } else {
          this.$notification.error({
            message: "CAN'T JOIN VIDEO CONFERENCE ROOM",
            description: 'This class has no zoom data for entering the room. Please contact teacher/admin for further information.',
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
    // this.findWeekSlot(idSlot)
  },
  computed: {
    currentSlot() { return this.$store.state.slot.currentSlot },
    sessionDate() { return moment(this.session.tanggal_sesi, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY') },
    sessionTime() {
      return moment(this.time, 'HH:mm').locale('id').format('HH:mm')
    },
    materials() { return this.session.topik || {} },
    isLoading() { return this.$store.state.isLoading },
    videoUrls() {
      const data = []
      for (let i = 1; i <= 5; i++) {
        if (this.materials[`video_url${i}`] && this.materials[`video_url${i}`] !== '') {
          data.push(this.materials[`video_url${i}`])
        }
      }
      return data
    },
    fileMaterials() {
      return this.$store.state.slot.fileMaterials
    },
    isLive() { return this.session.zoom_setting },
    user() { return this.$store.state.user.user },
    isNow() {
      // return true
      // return moment().days() === this.time.days()
      // console.log(moment().isSame(this.time, 'days'), this.time.format('dddd'), moment().format('dddd'), 'tes')
      // return moment().isSame(this.time, 'days') && moment().isBefore(moment('13:00', 'HH:mm')) && moment().isAfter(moment('06:00', 'HH:mm'))
      return moment().isSame(this.time, 'days')
    },
    isInSubjectHours() {
      return moment().isBetween(this.time, this.nextTime, undefined, '[)')
    },
    isDone() {
      // console.log(moment().isBetween(time, nextTime, undefined, '[]'))
      return this.nextTime < moment() && moment().isAfter(moment('13:00', 'HH:mm'))
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    kelas() {
      if (this.session.slot?.kela) {
        return `${this.session.slot.kela.level.nama} - ${this.session.slot.kela.simbol}`
      }
      return '-'
    },
    mapel() {
      return this.session.slot?.mata_pelajaran.nama
    },
    week() {
      return this.session.week?.week
    },
    current: {
      get: function() {
        return this.$store.state.menu.currentDetailScheduleMenu
      },
      set: function(value) {
        this.$store.commit('menu/SET_STATE', {
          currentDetailScheduleMenu: value,
        })
      },
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
  // beforeRouteLeave (to, from, next) {
  //   this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
  //     idMurid: this.user.id,
  //     idSession: this.currentSlot.id,
  //   })
  //     .then(({ data }) => {
  //       let unlinkArr = []
  //       if (data.notes && this.uploadedImages.length) {
  //         const regexImages = /(images\/).+?(?=")/gm
  //         // let image
  //         // const regexImages = /(?<=images\/).+?(?=")/gm
  //         // let image
  //         const arrImageOld = data.notes.match(regexImages)
  //         if (arrImageOld) {
  //           const arrImage = arrImageOld.map(image => {
  //             return image[0].split('images/')[1]
  //           })
  //           for (let j = 0; j < this.uploadedImages.length; j++) {
  //             const image = this.uploadedImages[j]
  //             for (let i = 0; i < arrImage; i++) {
  //               if (arrImage[i] === image) {
  //                 break
  //               } else if (i === arrImage.length - 1 && arrImage[i] !== image) {
  //                 unlinkArr.push(image)
  //               }
  //             }
  //           }
  //         }
  //       } else if (!data.notes && this.uploadedImages.length) {
  //         unlinkArr = this.uploadedImages
  //       }
  //       console.log(unlinkArr)
  //       if (unlinkArr.length) {
  //         this.$store.dispatch('UNLINK_IMAGE', { unlinkImages: unlinkArr })
  //       }
  //     })
  //   next()
  // },
}
</script>
<style lang="scss">
.detailSesi {
  overflow-x: hidden;
  #coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 40px;
    color: #ffffff;
    /* opacity: 0.8;
    z-index: 10; */
  }

  .countdownTImer {
    width: 211px;
    height: 120px;
    background-color: white;
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 99999;
    box-shadow: 0px 5px 15px #6d807b80;
    display: flex;
    flex-direction: column;
    padding: 30px;
    h4 {
      color: #041930;
    }
    h2 {
      margin: 0;
      color: #ff0000;
    }
  }

  h2 {
    color: #ffffff;
  }

  .menuSchedule {
    margin-bottom: 35px;
  }

  #contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0419301a;
    margin-left: 1px;
    @media (max-width: 425px) {
      padding: 20px;
    }
  }

  .subMenuDetail {
    font-family: "Mukta", "sans-serif";
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1.5px;
    /* color: #041930; */
  }

  .form-submit {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding: 10px 45px;
    background: var(--kit-color-primary) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    border-style: none;
    color: #ffffff;
  }

  .form-submit:active {
    border-style: none;
  }
}
</style>
